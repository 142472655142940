.wrapper {
  padding: 0 0 16px 0;
  :global {
    .ant-form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 16px;
    }
  }
}
.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #232b3a;
  margin: 0;
  position: relative;
  padding-left: 10px;
  display: flex;
  align-items: center;
  &:before {
    content: ' ';
    display: block;
    background: #196eff;
    border-radius: 8px;
    width: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
  }
}
.endMessage {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #8991a0;
  gap: 12px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.stepWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}
.skipAllBtn {
  margin-left: auto;
}
.defectSearchIcon {
  margin-left: 6px;
  color: #8991a0;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
