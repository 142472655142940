@import 'src/assets/scss/constants';

:global {
  .ant-modal {
    max-width: $side-max-width !important;
  }
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.wrapper {
  &.warning {
    :global {
      .ant-modal-content {
        margin-left: 40px;
        margin-right: 40px;
        border-radius: 16px;
      }
      .ant-modal-body {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 24px 16px;
      }
    }
  }
  &.bottom {
    height: calc(100% - 28px);
    top: 28px !important;
    padding-bottom: 0;
    :global {
      .ant-modal-content {
        height: 100%;
        border-radius: 16px 16px 0 0;
        display: flex;
        flex-direction: column;
      }
      .ant-modal-header {
        border-radius: 16px 16px 0 0;
        .ant-modal-title {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: #232b3a;
        }
      }
      .ant-modal-body {
        height: 100%;
        overflow: auto;
      }
    }
  }
}
.warningContent {
  text-align: center;
}
.warningFooter {
  display: flex;
  gap: 16px;
  > * {
    flex: 1;
  }
}

@media only screen and (max-width: 420px) {
  :global {
    .ant-modal {
      max-width: 100% !important;
    }
  }
}
