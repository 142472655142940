@import 'src/assets/scss/mixins';

.wrapper {
  &.active {
    .jobHeader {
      &:before {
        border-color: #196eff;
        background: #196eff;
      }
    }
  }
  &.red {
    .jobHeader {
      &:before {
        border-color: #ed4646;
        background: #ed4646;
      }
    }
  }
  &:hover,
  &:focus,
  &:visited {
    background: #ebedef;
    box-shadow: 0px 2px 4px 1px rgba(165, 178, 200, 0.1),
      0px 1px 6px rgba(165, 178, 200, 0.2);
  }
}
.jobHeader {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 20px;
  text-align: left;
  &:before {
    content: ' ';
    display: block;
    border-radius: 10px;
    width: 4px;
    border: 1px solid #c4c8d0;
    background: transparent;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
  h1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 6px 0;
    color: #232b3a;
    @include ellipsisLongText();
  }
  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #8991a0;
    margin: 0;
    @include ellipsisLongText();
  }
}
.jobContent {
  margin-top: 8px;
  p {
    margin: 0 0 8px 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #232b3a;
  }
}
.userIcon {
  width: 16px;
  height: 16px;
  background: #f3effb;
  border-radius: 50px;
  display: inline-flex;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
}
