.wrapper {
  padding: 12px 0;
}
.inputWrapper {
  padding: 16px;
  margin-bottom: 10px;
  textarea {
    min-height: 168px;
    border-radius: 4px;
  }
  label {
    margin-bottom: 4px;
    display: inline-block;
  }
}
