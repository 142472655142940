:global {
  .ant-upload {
    background: white;
  }
  .ant-upload-list-picture-card-container {
    width: auto;
    height: auto;
    .ant-upload-list-item-thumbnail {
      img {
        width: 96px;
        height: 96px;
      }
    }
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 96px;
    height: 96px;
  }
  .ant-upload-list {
    &.ant-upload-list-picture-card {
      // > div {
      //   &:nth-child(3n + 3) {
      //     margin-right: 0;
      //   }
      // }
      .ant-upload-list-item {
        padding: 0;
        border-radius: 4px;
        .ant-upload-list-item-info {
          border-radius: 4px;
        }
      }
    }
  }
  .ant-upload-drag-container {
    color: #668bc9;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    .anticon {
      font-size: 24px;
      margin-bottom: 4px;
    }
  }
}
