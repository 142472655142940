.wrapper {
  text-decoration: underline;
  cursor: pointer;
}
.toast {
  :global {
    .ant-message-notice-content {
      background: rgba(0, 0, 0, 0.7);
      box-shadow: 0px 2px 4px 1px rgba(165, 178, 200, 0.1),
        0px 3px 8px 1px rgba(165, 178, 200, 0.3);
      border-radius: 4px;
      padding: 12px 16px;
    }
    .ant-message-success {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: white;
      > .anticon {
        display: none;
      }
    }
  }
}
:global {
  .i-copy-message.ant-message {
    top: auto !important;
    bottom: 30px !important;
  }
}
