.wrapper {
  display: flex;
  background: white;
  margin-bottom: 0;
  padding-left: 0;
  margin-left: -12px;
  margin-top: -16px;
  margin-right: -12px;
  position: sticky;
  top: 56px;
  z-index: 2;
  min-height: 40px;
  max-height: 40px;
  align-items: center;
  :global {
    .ant-form-item-control-input-content {
      padding-right: 55px;
      display: flex;
      align-items: center;
      position: relative;
      top: 12px;
    }
  }
  .inner {
    width: 100%;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex: 1;
    li {
      list-style: none;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      position: relative;
      min-height: 40px;
      gap: 4px;
      max-width: 88px;
      cursor: pointer;
      > span {
        &:nth-child(1) {
          display: flex;
          width: 18px;
          height: 18px;
          background: #ebedef;
          color: #4e5a71;
          font-weight: 700;
          font-size: 11px;
          line-height: 11px;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }
        &:nth-child(2) {
          font-weight: 400;
          font-size: 12px;
          line-height: 12px;
          color: #8991a0;
        }
      }
      &:after {
        content: ' ';
        position: absolute;
        left: 10px;
        right: 10px;
        height: 3px;
        border-top: 3px solid #196eff;
        border-radius: 8px;
        bottom: 0;
        display: none;
      }
      &.active {
        &:after {
          display: block;
        }
      }
      &.completed {
        > span {
          &:nth-child(1) {
            background: #196eff;
            color: white;
          }
          &:nth-child(2) {
            color: #196eff;
          }
        }
      }
    }
  }
  &.isViewLastStep {
    :global {
      .ant-form-item-control-input-content {
        padding-right: 0;
        padding-left: 55px;
      }
    }
  }
}
.arrow {
  position: absolute;
  &.right {
    right: 16px;
  }
  &.left {
    left: 16px;
  }
}
