.wrapper {
  background: white;
  ul {
    list-style: none;
    padding: 0 16px;
    margin: 0;
    > li {
      cursor: pointer;
      list-style: none;
      padding: 12px 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #232B3A;
      border-bottom: 1px solid #EBEDEF;
      display: flex;
      align-items: center;
      > span {
        display: inline-flex;
        width: 24px;
        height: 24px;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        visibility: hidden;
      }
      &:last-child {
        border: 0;
      }
      &.active {
        > span {
          visibility: visible;
        }
      }
    }
  }
}