:global {
  .ant-alert {
    backdrop-filter: blur(10px);
    border-radius: 8px;
    &.ant-alert-error {
      background: #ffebeb;
      border-color: #ffebeb;
      .ant-alert-message {
        color: #ed4646;
      }
    }
    &.ant-alert-warning {
      background: #fffaea;
      border-color: #fffaea;
      .ant-alert-message {
        color: #ff961e;
      }
    }
  }
}
