.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #232b3a;
    margin: 0;
  }
  > div {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    :global {
      .anticon {
        color: #32c832;
      }
    }
  }
}
.stepNumber {
  background: #ebedef;
  border-radius: 32px;
  font-weight: 700;
  font-size: 11px;
  line-height: 11px;
  color: #4e5a71;
  display: flex;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
}
.content {
  background: #fafafa;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .contentInner {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    .sectionInsideArray {
      border-top: 1px solid #ebedef;
      padding-top: 8px;
      &.nonBorder {
        border: 0;
        padding-top: 0;
      }
    }
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.actionBtn {
  padding: 0;
  height: auto;
}
.resultDisplay {
  display: flex;
  align-items: center;
  gap: 2px;
  &.resultEmpty {
    svg {
      path {
        fill: #8991a0;
      }
    }
  }
}
.color {
  font-weight: 500;
  &.red {
    color: #ed4646;
  }
  &.green {
    color: #1ec000;
  }
}
