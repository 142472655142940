.modalContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 100%;
  > p {
    color: #8991a0;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
  }
}
.switchBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
  > span {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #232b3a;
  }
  button {
    font-weight: 500;
    border-radius: 4px !important;
  }
}
.inputBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0 0 0;
  > label {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    color: #232b3a;
  }
  &.last {
    padding-bottom: 16px;
  }
}
.buttons {
  display: flex;
  gap: 16px;
  margin-top: auto;
  > * {
    flex: 1;
  }
  :global {
    .ant-btn {
      font-weight: 500;
    }
    .ant-btn-ghost {
      color: #777777;
      border-color: #777777;
    }
  }
}

.drawSignature {
  canvas {
    background: #ffffff;
    border: 1px solid #c4c8d0;
    border-radius: 4px;
  }
  &.isFocus {
    canvas {
      border-color: #005eff;
    }
  }
}
.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  > span {
    &:nth-child(1) {
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      color: #232b3a;
    }
    &:nth-child(2) {
      color: #005eff;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      cursor: pointer;
    }
  }
}
.signUpload {
  :global {
    .ant-upload.ant-upload-drag {
      height: 96px;
      background: white;
      border-color: #668bc9;
    }
    .ant-upload-drag-container {
      color: #668bc9;
    }
    .ant-upload-list-item-info {
      background: transparent !important;
    }
    .ant-upload-list-item .ant-upload-list-item-card-actions-btn {
      opacity: 1 !important;
    }
  }
  .signUploadImageWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    > img {
      height: 100%;
      width: 100%;
      max-height: 164px;
      max-width: 100%;
      border: 1px solid #c4c8d0;
      border-radius: 4px;
    }
  }
}
.uploadedFileBlock {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 8px 12px 8px 8px;
  .uploadedFileDelete {
    margin-left: auto;
    cursor: pointer;
  }
  > span {
    display: flex;
    flex-direction: column;
    > span {
      &:nth-child(1) {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #232b3a;
      }
      &:nth-child(2) {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: #8991a0;
      }
    }
  }
}
