:global {
  .ant-form-item-label {
    > label {
      &:before {
        display: none !important;
      }
    }
  }
  .ant-form-item-control-input {
    min-height: 1px;
  }
}
