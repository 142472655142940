.wrapper {
  max-width: calc(100% - 32px);
  white-space: break-spaces;
  > span {
    display: block;
    width: 100%;
    background: #f0f0f0;
    border-radius: 8px;
    color: #232b3a;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 8px;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 8px;
    li {
      display: flex;
      color: #005eff;
    }
  }
  &.isMe {
    align-self: flex-end;
    > span {
      background: #005eff;
      color: white;
    }
  }
}
