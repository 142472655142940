.forgotPassword {
  display: flex;
  justify-content: flex-end;
  > span {
    color: #0c91c4;
    cursor: pointer;
    font-size: 14px;
  }
}
.wrapper {
  :global {
    .ant-form-item {
      margin-bottom: 12px;
      & + .ant-form-item {
        margin-bottom: 0;
      }
    }
    .ant-form {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-top: 50px;
      width: 100%;
      .ant-form-item-label {
        padding-bottom: 2px;
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background: #0c91c4;
      border-color: #0c91c4;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #0c91c4;
    }
    .ant-input:hover {
      border-color: #0c91c4;
    }
    .ant-input:focus,
    .ant-input-focused {
      border-color: #0c91c4;
      box-shadow: 0 0 0 2px #297eff33;
    }
  }
  a {
    color: #0c91c4;
  }
}
.acceptTerms {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
  > span {
    font-size: 13px;
    text-align: left;
  }
}
.buttons {
  margin-top: 16px;
  button {
    font-size: 16px;
    text-transform: uppercase;
    background: #0c91c4 !important;
    border-color: #0c91c4 !important;
  }
}
.sentSuccess {
  text-align: center;
  :global {
    .anticon {
      font-size: 60px;
      margin-bottom: 4px;
    }
  }
  h2 {
    font-size: 30px;
  }
  p {
    font-size: 16px;
    > span {
      color: #005eff;
      cursor: pointer;
      font-weight: 600;
    }
  }
}
