.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #4e5a71;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  > span {
    &:nth-child(2) {
      button {
        font-weight: 500;
      }
    }
  }
}
