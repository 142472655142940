@import 'src/assets/scss/constants';

.headerWrapper {
  display: flex;
  width: 100%;
  max-width: $side-max-width;
  align-items: center;
  padding: 10px 6px;
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px #f5f5f5;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  height: 54px;
  background: white;
  z-index: 5;
  h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #8991a0;
    margin: 0 0 0 6px;
  }
  .avatar {
    margin-left: auto;
    cursor: pointer;
  }
  .logo {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
