:global {
  .ant-badge-count-sm {
    font-size: 10px;
  }
  .ant-badge-count {
    top: 2px;
    right: 2px;
  }
  .ant-badge-multiple-words {
    padding: 0 4px;
  }
}
