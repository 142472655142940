@import 'src/assets/scss/constants';
@import 'src/assets/scss/mixins';

.wrapper {
  &:global(.ant-tabs) {
    :global {
      .ant-tabs-nav {
        padding-left: 12px;
        padding-right: 12px;
        background: white;
        // @include fixedEl();
        // top: 54px;
        // width: 100%;
        margin-bottom: 0;
        margin-left: -12px;
        margin-top: -12px;
        margin-right: -12px;
        position: sticky;
        top: 0;
        z-index: 2;
        .ant-tabs-tab {
          height: 40px;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          padding: 4px 8px 0 8px;
          color: #c4c8d0;
          & + .ant-tabs-tab {
            margin-left: 8px;
          }
        }
      }
    }
  }
}
