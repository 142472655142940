@import 'src/assets/scss/constants';

@mixin tooLongText($line: 1) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
}
@mixin ellipsisLongText() {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@mixin hideScrollBar() {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
@mixin commonLayout() {
  max-width: $side-max-width;
  width: 100%;
  margin: 0 auto;
  background: white;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
@mixin fixedEl() {
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 5;
  max-width: $side-max-width;
}
