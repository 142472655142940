.wrapper {
  box-shadow: 0px 2px 4px 1px rgba(165, 178, 200, 0.1),
    0px 1px 6px rgba(165, 178, 200, 0.2);
  border-radius: 8px;
  background: white;
  padding: 12px;
  &.flex {
    display: flex;
    &.hasMore {
      > div {
        display: flex;
      }
    }
    &.row {
      flex-direction: row;
      &.hasMore {
        > div {
          flex-direction: row;
        }
      }
    }
    &.column {
      flex-direction: column;
      &.hasMore {
        > div {
          flex-direction: column;
        }
      }
    }
  }
  &.pointer {
    cursor: pointer;
  }
  &.topHalfRadius {
    border-radius: 0 0 8px 8px;
  }
  &.moreRevert {
    .moreIcon {
      > svg {
        transform: rotate(180deg);
      }
    }
  }
  &.hasLoading {
    position: relative;
  }
}
.moreIcon {
  margin-left: auto;
  align-self: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background: #ffffff99;
  width: 100%;
  height: 100%;
  display: flex;
}
