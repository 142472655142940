:global {
  .ant-btn {
    border-radius: 8px;
    &.ant-btn-default {
      background: #f0f0f0;
      border-color: #cccccc;
    }
    &.ant-btn-color-white {
      background: white;
      color: #777777;
      &.ant-btn-ghost {
        border-color: #d9d9d9 !important;
      }
    }
    // &.ant-btn-primary {
    //   &.ant-btn-background-ghost {
    //     border-color: #005eff33;
    //   }
    // }
  }
  .ant-btn-lg {
    height: 46px;
    font-size: 14px;
    font-weight: 700;
  }
  .ant-btn-sm {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    padding-right: 8px;
    padding-left: 8px;
  }
}
.wrapper {
  &.fullWidth {
    width: 100%;
    max-width: 100%;
  }
  &.hasIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &.textWeight_lg {
    font-weight: 500;
    > span {
      font-weight: 500;
    }
  }
}
