@import '../Auth.module.scss';

.error {
  color: red;
}
.switchLanguage {
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    display: inline-flex;
    gap: 16px;
    > span {
      text-decoration: underline;
      cursor: pointer;
      font-weight: 600;
      position: relative;
      &:not(:last-child) {
        &:before {
          border-right: 2px solid black;
          content: ' ';
          width: 2px;
          position: absolute;
          right: -8px;
          top: 3px;
          bottom: 3px;
        }
      }
    }
  }
}
.acceptTerms {
  .checkbox {
    margin-bottom: 0;
  }
}
