.information {
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    > span {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      > span {
        width: 16px;
        height: 16px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-left: 4px;
      }
    }
  }
  h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0;
  }
}
.list {
  list-style: none;
  padding: 0;
  margin: 0;
  > li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
    > span {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #232b3a;
      &:first-child {
        color: #8991a0;
        min-width: 151px;
        display: inline-block;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
  :global {
    .ant-tag {
      margin-right: 0;
    }
  }
}
.listContent {
  margin-top: 8px;
}
