@import '~antd/dist/antd.less';

body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  > div {
    height: 100%;
    overflow: hidden;
  }
}
.hide {
  display: none !important;
}
.no-margin {
  margin: 0 !important;
}
@primary-color: #005EFF;