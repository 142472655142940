@import 'src/assets/scss/mixins';

.wrapper {
  @include commonLayout();
  padding: 16px;
  align-items: center;
  gap: 20px;
  h1 {
    color: #0c91c4;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    line-height: normal;
  }
}
.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  svg {
    width: 110px;
    height: 50px;
  }
}
.inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
}
