:global {
  .ant-tag {
    border-radius: 6px;
    border: 0;
    font-weight: 500;
    &.ant-tag-default {
      background: #f0f0f0;
      color: #8991a0;
    }
    &.ant-tag-orange {
      background: #fffaea;
      color: #ffc625;
    }
    &.ant-tag-blue {
      background: #e5efff;
      color: #005eff;
    }
    &.ant-tag-green {
      background: #f3fcf3;
      color: #32c832;
    }
    &.ant-tag-red {
      background: #fff5f2;
      color: #ff6e58;
      font-weight: 700;
    }
  }
}
