@import 'src/assets/scss/mixins';

.actionBtn {
  position: absolute;
  right: 0;
  top: -40px;
  font-weight: bold;
  padding-left: 0;
  padding-right: 0;
  svg {
    margin-left: 4px;
    path {
      fill: #005eff;
    }
  }
  &.headerHadBorder {
    top: -57px;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 32px;
  :global {
    .ant-form {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }
  &.isSection4 {
    .section5Form {
      display: none;
    }
    .section4Form {
      display: flex;
    }
  }
  &.isSection5 {
    .section4Form {
      display: none;
    }
    .section5Form {
      display: flex;
    }
  }
}
.section5Form,
.section4Form {
  display: flex;
  flex-direction: column;
  gap: 32px;
  font-style: normal;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #8991a0;
  font-style: normal;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 8px;
    li {
      img {
        width: 65px;
        height: 65px;
        border-radius: 8px;
      }
    }
  }
  .status {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #232b3a;
    display: flex;
    align-items: center;
    gap: 2px;
  }
}
.modalWrapper {
  :global {
    .ant-modal-header {
      .ant-modal-title {
        padding-right: 30px;
        @include ellipsisLongText();
      }
    }
  }
}
.calPercent {
  color: #8991a0;
  &.isGreen {
    color: #1ec000;
  }
  &.isRed {
    color: #ed4646;
  }
}
.isYellow {
  color: #ff961e;
}
.referenceFiles {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  > div {
    display: flex;
    align-items: center;
    gap: 16px;
    border-bottom: 1px solid #ebedef;
    padding-bottom: 12px;
    > * {
      &:last-child {
        margin-left: auto;
        cursor: pointer;
      }
    }
    > span {
      display: flex;
      flex-direction: column;
      position: relative;
      top: 2px;
      > span {
        &:nth-child(2) {
          color: #8991a0;
        }
      }
    }
    &:last-child {
      border: 0;
    }
  }
}
