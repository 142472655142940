.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}
.content {
  padding: 12px 20px;
  background: white;
  :global {
    .i-remark-content {
      white-space: break-spaces;
      word-break: break-all;
      line-height: 100%;
    }
  }
}
