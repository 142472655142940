.wrapper {
  &.expanded {
    .inner {
      min-height: 50vh;
      max-height: 50vh;
    }
  }
}
.inner {
  display: flex;
  gap: 16px;
  flex-direction: column;
  :global {
    .ant-upload-list.ant-upload-list-text {
      display: none !important;
    }
  }

  textarea {
    height: 100% !important;
    min-height: 100% !important;
    resize: none;
  }
  .chatInput {
    display: flex;
    gap: 8px;
    flex: 1;
    > span {
      margin-top: auto;
    }
    :global {
      .anticon {
        font-size: 20px;
        margin-bottom: 10px;
        color: #196eff;
        cursor: pointer;
        margin-top: auto;
      }
    }
  }
}
.input {
  flex: 1;
  :global {
    .ant-input {
      border-radius: 4px;
    }
  }
}
.sendIcon {
  &.sendActive {
    svg {
      path {
        fill: #196eff;
      }
    }
  }
}
.listAttachments {
  min-width: 100%;
  max-width: 100%;
  max-height: 30vh;
  overflow: auto;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    gap: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    li {
      list-style: none;
      display: flex;
      gap: 16px;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      :global {
        .anticon {
          cursor: pointer;
          color: #ff5555;
        }
      }
    }
  }
}
