.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 250, 234, 0.75);
  backdrop-filter: blur(10px);
  padding: 10px 14px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #232b3a;
  position: sticky;
  bottom: 0px;
  margin: auto -12px -12px -12px;
  flex-wrap: wrap;
  > span {
    display: flex;
    align-items: center;
    display: flex;
    gap: 8px;
    &:nth-child(2) {
      cursor: pointer;
    }
  }
  .expandContent {
    min-width: 100%;
    max-width: 100%;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px 0;
    > span {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #8991a0;
    }
  }
}
.checkInFlow {
  width: 100%;
  display: flex;
  &.box {
    background: #ffffff;
    border-radius: 16px;
    padding: 32px 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    :global {
      .ant-btn {
        flex: 1;
      }
    }
  }
}
.checkoutContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: 100%;
  text-align: center;
  > svg {
    margin-bottom: 8px;
  }
  & + div {
    display: flex;
    gap: 16px;
    :global {
      .ant-btn {
        flex: 1;
      }
    }
  }
}
