.inner {
  display: flex;
  align-items: center;
  > svg {
    margin-right: 10px;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 4px;
    color: #232b3a;
    & + span,
    & + span + span {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #8991a0;
    }
  }
}
.information {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
