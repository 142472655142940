.actionBtn {
  position: absolute;
  right: 0;
  top: -40px;
  font-weight: bold;
  padding-left: 0;
  padding-right: 0;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 32px;
  :global {
    .ant-form {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }
}
