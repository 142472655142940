:global {
  .ant-input,
  .ant-input-affix-wrapper {
    border-radius: 4px;
  }
  .ant-input-lg {
    font-size: 14px;
    min-height: 40px;
    max-height: 40px;
  }
  .ant-input-affix-wrapper-lg {
    padding: 0;
    font-size: 14px;
    > input.ant-input {
      padding: 6.5px 11px;
    }
    .ant-input-suffix {
      margin-right: 14px;
      color: #8991a0;
      font-size: 10px;
    }
    .ant-input-prefix {
      margin-left: 16px;
      margin-right: 8px;
      & + input {
        padding-left: 0;
      }
    }
  }
}
