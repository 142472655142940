.information {
  position: sticky;
  top: 56px;
}
.inner {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
  h2 {
    margin: 12px 0 0 0;
  }
}
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}
.submitBtn {
  margin-top: auto;
}
.submitModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  gap: 16px;
  p {
    text-align: center;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #232b3a;
  }
}
.submitWrapper {
  display: flex;
  width: 100%;
  gap: 12px;
  margin-top: auto;
  > * {
    flex: 1;
  }
}
.pageHeader {
  height: 56px;
}
