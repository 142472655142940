.wrapper {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #c4c8d0;
  }
}
