.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}
.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #232b3a;
  height: 48px;
  padding: 0 16px;
  background: white;
  box-shadow: 0px 2px 4px 1px rgba(165, 178, 200, 0.1),
    0px 1px 6px rgba(165, 178, 200, 0.2);
  position: relative;
  z-index: 2;
  > span {
    &:last-child {
      color: #005eff;
      cursor: pointer;
    }
  }
}
.content {
  background: white;
  > ul {
    list-style: none;
    padding: 0 10px;
    margin: 0;
    > li {
      list-style: none;
      display: flex;
      align-items: center;
      padding: 18px 8px;
      border-bottom: 1px solid #ebedef;
      > svg {
        margin-right: 20px;
      }
      > div {
        display: flex;
        flex-direction: column;
        > h4 {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #232b3a;
          margin-bottom: 0;
        }
        > span {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #8991a0;
        }
      }
      > span {
        margin-left: auto;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
.downloadAll {
  display: flex;
  align-items: center;
  gap: 8px;
}
