:global {
  .ant-picker-calendar .ant-picker-panel {
    border-top: 0;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: #196eff;
    color: white !important;
    &:before {
      display: none;
    }
  }
  .ant-picker-cell {
    &:before {
      display: none;
    }
    .ant-picker-cell-inner {
      border-radius: 50% !important;
      padding: 9px;
      height: 36px;
      min-height: 36px;
      width: 36px;
      min-width: 36px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      &:before {
        display: none;
      }
    }
  }
}
.header {
  display: flex;
  align-self: center;
  justify-content: space-between;
  margin-bottom: 8px;
  .date {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #3a4861;
  }
}
.wrapper {
  &.weekView {
    :global {
      .ant-picker-cell-disabled {
        display: none;
      }
      .ant-picker-content {
        height: 50px;
      }
    }
  }
  :global {
    .ant-picker-calendar-date-content {
      .i-calendar-mark {
        width: 5px;
        height: 5px;
        display: block;
        background: #d9d9d9;
        border-radius: 50%;
        position: absolute;
        bottom: 4px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
    .ant-picker-cell-in-view {
      &.ant-picker-cell-today {
        .ant-picker-cell-inner {
          background: gray;
          color: white;
        }
        &:hover {
          .ant-picker-cell-inner {
            color: black;
          }
        }
      }
      &.ant-picker-cell-selected {
        .ant-picker-cell-inner {
          background: #196eff;
          color: white;
        }
        .ant-picker-calendar-date-content {
          .i-calendar-mark {
            background: #ebedef;
          }
        }
      }
    }
  }
}
