.section {
  color: #232b3a;
  display: flex;
  flex-direction: column;
  flex: 1;
  > div:first-child {
    height: 32px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    > h3 {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin: 0;
    }
  }
  ul {
    padding: 0;
    list-style: none;
    margin: 0;
    width: 100%;
    gap: 8px;
    display: flex;
    flex-direction: column;
    > li {
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 25px;
      > span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        &:first-child {
          color: #8991a0;
          max-width: 140px;
          min-width: 140px;
        }
        &:last-child {
          text-align: right;
          word-break: break-all;
        }
      }
      &.itemNoLabel {
        > span {
          text-align: left;
          color: #232b3a;
        }
      }
    }
  }
  &.border {
    border-bottom: 1px solid #ebedef;
    padding-bottom: 12px;
  }
  &.listBox {
    ul {
      padding: 8px;
      background: #fafafa;
      border-radius: 8px;
    }
  }
  &.box {
    padding: 8px 12px;
    border-radius: 8px;
  }
}
.wrapper {
  > * {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.itemAsArray {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  > ul {
    > h3 {
      margin-bottom: -4px;
    }
  }
  &.arrayHasBorder {
    > ul {
      border-top: 1px solid #ebedef;
      padding-top: 4px;
      &:first-child {
        border-top: 0;
        padding-top: 0;
      }
    }
  }
}
.childrenWrapper {
  font-size: 12px;
}
.subBoxWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 100%;
  max-width: 100%;
  > * {
    flex: 1;
  }
}
.subBox {
  border-radius: 4px;
  border: 1px solid #ccc;
  background: #f0f0f0;
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #232b3a;
    > span {
      &:nth-child(1) {
        color: #8991a0;
      }
    }
  }
}
