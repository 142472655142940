.wrapper {
  position: relative;
  padding-bottom: 8px;
  &.noStyle {
    :global {
      .ant-collapse {
        border: 0;
        .ant-collapse-item {
          border: 0;
        }
      }
      .ant-collapse-header {
        padding: 0;
      }
      .ant-collapse-expand-icon {
        display: none;
      }
      .ant-collapse-content {
        border: 0 !important;
      }
      .ant-collapse-header-text {
        cursor: default;
      }
      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
  &.box {
    :global {
      .ant-collapse {
        box-shadow: 0px 2px 4px 1px rgba(165, 178, 200, 0.1),
          0px 1px 6px rgba(165, 178, 200, 0.2);
        border-radius: 0 0 8px 8px;
        background: white;
        padding: 12px 12px 16px 12px;
      }
    }
  }
  &.margin {
    margin-left: -12px;
    margin-right: -12px;
  }
  &.revert {
    padding-bottom: 0;
    padding-top: 8px;
    :global {
      .ant-collapse {
        padding-top: 20px;
        border-radius: 8px 8px 0 0;
        box-shadow: 0px -2px 4px 1px rgba(165, 178, 200, 0.1),
          0px -1px 6px rgba(165, 178, 200, 0.2);
      }
    }
    .icon {
      bottom: auto;
      top: 10px;
    }
  }
}
.icon {
  position: absolute;
  bottom: 10px;
  width: 100%;
  height: 12px;
  cursor: pointer;
  > span {
    background: #ebedef;
    border-radius: 100px;
    height: 4px;
    width: 40px;
    display: block;
    margin: auto;
    margin-top: 4px;
  }
}
