@import 'src/assets/scss/constants';

.navWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: $side-max-width;
  height: 50px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 1px 6px rgba(165, 178, 200, 0.15),
    0px 0px 4px rgba(165, 178, 200, 0.15);
  backdrop-filter: blur(10px);
  margin: 0 auto;
  ul {
    list-style: none;
    padding: 0px 35px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    li {
      > span,
      a {
        cursor: pointer;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 13px 15px;
        svg {
          > path {
            fill: #8991a0;
          }
        }
        &:hover,
        &.active {
          svg {
            > path {
              fill: #196eff;
            }
          }
        }
      }
    }
  }
}
