@import 'src/assets/scss/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  position: relative;
  h1 {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #232B3A;
    margin-top: 0;
    margin-bottom: 8px;
  }
  > p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #4E5A71;
    height: 32px;
    margin-bottom: 8px;
    @include tooLongText(2);
  }
  > div {
    display: flex;
    justify-content: space-between;
    > span {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #8991A0;
      &:nth-child(2) {
        color: #196EFF;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
.unreadMark {
  position: absolute;
  left: 8px;
  top: 12px;
  display: block;
  width: 8px;
  height: 8px;
  display: inline-block;
  background: #FF5555;
  border-radius: 100%;
}
.arrow {
  position: absolute;
  display: inline-flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  right: 12px;
  top: 12px;
}