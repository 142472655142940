:global {
  .ant-radio-inner {
    width: 20px;
    height: 20px;
    border-color: #dadada;
  }
  .ant-radio {
    position: relative;
    top: 4px;
    &:after {
      display: none !important;
    }
    &.ant-radio-checked {
      .ant-radio-inner {
        border-color: #3a4861;
        &:after {
          background-color: #3a4861;
        }
      }
    }
  }
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px #3a48611f !important;
  }
}
.wrapper {
  &.fullWidth {
    width: 100%;
    :global {
      .ant-space {
        width: 100%;
        > .ant-space-item {
          > .ant-radio-wrapper {
            border: 1px solid #3a4861;
            border-radius: 6px;
            width: 100%;
            padding: 14px 26px;
            margin-right: 0;
          }
        }
      }
    }
  }
}
