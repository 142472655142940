.wrapper {
  display: flex;
  justify-content: space-between;
  position: sticky;
  bottom: 24px;
  margin-top: auto;
  .buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    > * {
      flex: 1;
      min-width: calc(50% - 5px);
      max-width: calc(50% - 5px);
    }
  }
}
