.wrapper {
  &.active {
    > path {
      &:nth-child(1),
      &:nth-child(2) {
        fill: #196eff;
      }
    }
  }
}
