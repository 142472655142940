@import 'src/assets/scss/mixins';

.publicLayoutWrapper {
  @include commonLayout();
  // height: auto;
  // overflow: auto;
  // max-width: 100%;
  padding: 54px 0 50px 0;
  &.hideHeader {
    padding-top: 0;
  }
  &.whiteInner {
    .inner {
      background: white;
    }
  }
  &.gray1Inner {
    .inner {
      background: #f5f5f5;
    }
  }
  &.notPadding {
    .inner {
      padding: 0;
    }
  }
  &.hideNav {
    padding-bottom: 0;
  }
}
.inner {
  background: #f8f8f8;
  padding: 12px;
  flex: 1;
}
