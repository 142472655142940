.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  > h3 {
    margin: 0;
    font-size: 12px;
    color: #8991a0;
    font-weight: 400;
    line-height: 16px;
  }
}
.inner {
  padding: 16px 4px 16px 8px;
  background: white;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  align-items: center;
  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    > span {
      &:nth-child(1) {
        font-size: 16px;
        color: #232b3a;
        font-weight: 500;
        line-height: 19px;
      }
      &:nth-child(2) {
        font-size: 10px;
        font-weight: 400;
        color: #8991a0;
        line-height: 12px;
      }
    }
  }
}
.arrowRightIcon {
  margin-left: auto;
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
}
.edit {
  margin-left: auto;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  cursor: pointer;
  color: #005eff;
}
