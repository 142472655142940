@import 'src/assets/scss/mixins';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  box-shadow: inset 0px -1px 0px #f5f5f5, inset 0px 1px 0px rgba(0, 0, 0, 0.1);
  padding: 16px 20px 16px 12px;
  gap: 16px;
  > * {
    display: flex;
  }
  > div {
    flex: 1;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #232b3a;
    > svg {
      color: #3a4861;
    }
  }
  &.insidePageInner {
    margin: -12px -12px 0 -12px;
  }
  &.sticky {
    position: sticky;
    top: 0;
    z-index: 2;
  }
}
.back {
  display: inline-flex;
  min-width: 24px;
  height: 24px;
  margin-right: 4px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.chatLink {
  > * {
    display: flex;
  }
}
.saveStatus {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8991a0;
  display: flex;
  align-items: center;
  gap: 6px;
}
.left {
  width: calc(100% - 150px);
  position: relative;
  .title {
    @include ellipsisLongText();
    display: flex;
    flex-direction: column;
    > span {
      @include ellipsisLongText();
      &:nth-child(2) {
        color: #8991a0;
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        position: absolute;
        bottom: -10px;
      }
    }
  }
}
