.tabs {
  position: relative;
  :global {
    .ant-tabs-tab {
      .ant-tabs-tab-btn {
        position: relative;
        .ant-badge {
          position: absolute;
          top: -1px;
          right: -4px;
        }
      }
    }
    .ant-tabs-content-holder {
      // padding-top: 32px;
    }
  }
}
.wrapper {
  margin-top: 12px;
}
.filterIcon {
  width: 36px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.filterIsNotActive {
    &:hover {
      > svg {
        path {
          fill: #196eff;
        }
      }
    }
  }
}
.filterPanel {
  position: absolute;
  top: 39px;
  left: 0;
  right: 0;
}
