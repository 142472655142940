.wrapper {
  &:not(.circle) {
    &.loading {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffffe6;
    }
  }
}
