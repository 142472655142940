.modalWrapper {
  &:global(.ant-modal) {
    top: 122px !important;
    height: calc(100% - 122px);
    :global {
      .ant-modal-content {
        width: calc(100% - 20px);
        margin: 0 auto;
      }
    }
  }
}
.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  min-height: 160px;
  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #232b3a;
    margin: 0;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #4e5a71;
  }
}
.modalContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
  > * {
    flex: 1;
  }
}
