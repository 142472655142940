.contentPreview {
  background: white;
  padding: 16px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.blockOpened {
  border-radius: 8px;
  box-shadow: 0px 2px 4px 1px rgba(165, 178, 200, 0.1),
    0px 1px 6px rgba(165, 178, 200, 0.2);
  > div {
    &:nth-child(1) {
      border-radius: 8px 8px 0 0;
      box-shadow: none;
    }
  }
  .contentPreview {
    border-radius: 0 0 8px 8px;
    position: relative;
    &:before {
      content: ' ';
      display: block;
      position: absolute;
      height: 1px;
      border-top: 1px solid #ebedef;
      top: 0;
      left: 12px;
      right: 12px;
    }
  }
  :global {
    .i-block-more-icon {
      > svg {
        transform: rotate(90deg) !important;
      }
    }
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  padding-top: 12px;
  flex: 1;
}
.submitBtn {
  margin-top: auto;
}
